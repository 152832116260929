@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilites {
  .poster-gradient {
    background: linear-gradient(
      0deg,
      hsla(0, 0%, 0%, 0.8),
      hsla(0, 0%, 25%, 0.2) 23%,
      hsla(0, 0%, 100%, 0) 35%,
      transparent
    );
  }

  .profile-gradient {
    background-color: rgb(250, 245, 255);
    background-image: radial-gradient(
        at 63% 17%,
        rgb(34, 211, 238) 0,
        transparent 92%
      ),
      radial-gradient(at 31% 20%, rgb(240, 249, 255) 0, transparent 14%),
      radial-gradient(at 94% 84%, rgb(3, 105, 161) 0, transparent 82%),
      radial-gradient(at 21% 33%, rgb(254, 243, 199) 0, transparent 51%),
      radial-gradient(at 32% 31%, rgb(63, 98, 18) 0, transparent 72%),
      radial-gradient(at 70% 73%, rgb(245, 158, 11) 0, transparent 50%);
  }

  .text-shadow-sm {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  }
}

@layer utilities {
  .type-display-1 {
    @apply text-6xl font-semibold leading-none;
  }
  .type-header-1 {
    @apply text-5xl font-medium leading-none;
  }
  .type-header-2 {
    @apply text-4xl font-medium leading-none;
  }
  .type-header-3 {
    @apply text-3xl font-medium leading-none;
  }
  .type-subtitle-1 {
    @apply text-2xl font-medium leading-md;
  }
  .type-subtitle-2 {
    @apply text-xl font-medium leading-md;
  }
  .type-body-large-bold {
    @apply text-lg font-medium leading-md;
  }
  .type-body-large {
    @apply text-lg font-normal leading-md;
  }
  .type-body-1 {
    @apply text-md font-normal leading-md;
  }
  .type-body-1-bold {
    @apply text-md font-medium leading-md;
  }
  .type-body-2 {
    @apply text-base font-normal leading-md;
  }
  .type-body-2-bold {
    @apply text-base font-medium leading-md;
  }
  .type-body-3 {
    @apply text-sm font-normal leading-md;
  }
  .type-body-3-bold {
    @apply text-sm font-medium leading-md;
  }
  .type-body-4 {
    @apply text-xs font-normal leading-md;
  }
  .type-body-4-bold {
    @apply text-xs font-medium leading-md;
  }
  .type-button-1 {
    @apply text-md font-semibold leading-md tracking-btn;
  }
  .type-button-2 {
    @apply text-base font-semibold leading-md tracking-btn;
  }
  .type-button-3 {
    @apply text-xs font-semibold leading-md tracking-btn;
  }

  .centered {
    @apply flex justify-center items-center;
  }

  /* Sizes
    small
    medium
    large
  */
  .btn-sm {
    @apply h-btn-sm px-4 py-2 rounded-full type-button-3 centered gap-2 shadow-sm;
  }

  .btn-md {
    @apply h-btn-md px-5 py-3 rounded-full type-button-2 centered gap-2 shadow-lg;
  }

  .btn-lg {
    @apply h-btn-lg px-6 py-[14px] rounded-full type-button-1 centered gap-[10px] shadow-md;
  }

  /* Variants
    fill
    white transparent fill
    black outline
    ghost white
    ghost red
  */

  .btn {
    @apply rounded-full centered will-change-auto transition-all duration-200 cursor-pointer;
  }

  .btn-fill {
    @apply btn bg-red-200 bg-gradient-to-r from-red-700 to-red-200 text-white-500 hover:bg-red-300 hover:bg-none disabled:bg-none disabled:bg-red-700 disabled:opacity-50;
  }

  .btn-fill-white {
    @apply btn bg-white-500/20 text-white-500 hover:bg-opaqueWhite disabled:opacity-50;
  }

  .btn-black-outline {
    @apply btn bg-transparent text-black-700 border border-solid border-black-700 !shadow-none hover:border-red-500 hover:text-red-500 disabled:text-gray-300 disabled:border-gray-300;
  }

  .btn-ghost-white {
    @apply btn bg-transparent text-white-500 !shadow-none hover:text-off-white-300 disabled:text-gray-700;
  }

  .btn-ghost-red {
    @apply btn bg-transparent text-red-500 !shadow-none hover:bg-gray-100 hover:text-red-900 disabled:text-gray-300;
  }

  .icon-sm {
    @apply w-[14px] h-[14px] stroke-currentColor;
  }

  .icon-md {
    @apply w-5 h-5 stroke-currentColor;
  }

  .icon-lg {
    @apply w-6 h-6 stroke-currentColor;
  }
}
