.slide-pane {
  position: absolute;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  background: var(--color-black-700);
  min-width: 100px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s;
  will-change: transform;
}
.slide-pane:focus {
  outline-style: none;
}
.slide-pane_from_bottom {
  max-height: min-content;
  min-height: min-content;
  transform: translateY(100%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.slide-pane_from_bottom.content-after-open {
  transform: translateY(0%);
}
.slide-pane_from_bottom.content-before-close {
  transform: translateY(100%);
}
.slide-pane__overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.slide-pane__overlay.overlay-after-open {
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.5s;
}
.slide-pane__overlay.overlay-before-close {
  background-color: rgba(0, 0, 0, 0);
}

.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 24px 32px;
  flex: 1 1 auto;
}
.slide-pane_from_right {
  margin-left: auto;
  transform: translateX(100%);
}
.slide-pane_from_right.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_right.content-before-close {
  transform: translateX(100%);
}
.slide-pane_from_left {
  margin-right: auto;
  transform: translateX(-100%);
}
.slide-pane_from_left.content-after-open {
  transform: translateX(0%);
}
.slide-pane_from_left.content-before-close {
  transform: translateX(-100%);
}
